import React, {useState} from "react";
import PropTypes from "prop-types";
import AcknowledgeAlertModal from "./AcknowledgeAlertModal";
import _ from "lodash";

export const AcknowledgeAll = ({data, onSubmitCallback}) => {
    const [isModalOpen, updateIsModalOpen] = useState(false);
    const alertIds = data
        .filter(alert => !alert?.original?.acknowledged)
        .map(alert => alert?.original?.insertId)
    const missingPartitionTime = data.filter(alert => !alert.original?.partitionTime)
    return (
        <>
            <button className={'button primary acknowledge-all'} onClick={() => updateIsModalOpen(!isModalOpen)}>
                Acknowledge All
            </button>
            <AcknowledgeAlertModal
                isModalOpen={isModalOpen}
                updateIsModalOpen={updateIsModalOpen}
                buttonTitle={'Acknowledge all'}
                insertIds={alertIds}
                toastTextSuccess={`${alertIds.length} alerts acknowledged successfully`}
                toastTextFailure={'Failed to acknowledge alerts'}
                onSubmitCallback={onSubmitCallback}
                falsePositive={null}
                acknowledged={false}
                acknowledgeButtonEnabled={_.isEmpty(missingPartitionTime)}
            />
        </>
    );
}

AcknowledgeAll.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        insertId: PropTypes.string,
        Acknowledge: PropTypes.shape({
            acknowledged: PropTypes.bool
        }),
    })),
    onSubmitCallback: PropTypes.func
}