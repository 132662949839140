import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeApiCallPost} from "../../Api/makeApiCall";
import Modal from "react-modal";
import ButtonLoader from "../../utils/components/ButtonLoader";
import {RadioButtonGroup} from "../../utils/components/RadioButtonGroup";
import {retailerDisplay} from "../../utils/retailerDisplayFormatter";
import {getRetailerName} from "../../utils/retailerMapCache";
import AceEditor from "react-ace";
import {prettyFormatJsonString} from "../../utils/formatJson";

function getModalHeader(updateIsModalOpen, title) {
    return (
        <div className={"modal-header"}>
            <div className={'modal-header-title-container'}><span className={'modal-header-title'}>{title}</span></div>
            <div>
                <button className={"right-exit-button"} onClick={() => updateIsModalOpen(false)}>
                    <i className="fas fa-times"/></button>
            </div>
        </div>
    );
}

const AcknowledgeAlertModal = ({
                                   isModalOpen,
                                   updateIsModalOpen,
                                   retailerId,
                                   taskName,
                                   buttonTitle,
                                   insertIds,
                                   initialNotes,
                                   toastTextSuccess,
                                   toastTextFailure,
                                   onSubmitCallback,
                                   acknowledged,
                                   falsePositive,
                                   alertDetailsValue,
                                   acknowledgeButtonEnabled
                               }) => {
    const [isDataIssue, updateIsDataIssue] = useState(falsePositive === null ? null : !falsePositive)
    const [localNotes, updateLocalNotes] = useState(initialNotes === null ? '' : initialNotes);
    const isSingleAlertAcknowledgement = insertIds.length === 1 && retailerId

    function getAcknowledgedStatus(acknowledged) {
        return acknowledged ? 'Unacknowledge' : 'Acknowledge'
    }

    const modalTitle = getAcknowledgedStatus(acknowledged) + ` Alert - ${insertIds}`

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const taskNameForAcknowledgeAll = urlParams.get('task_name') ? urlParams.get('task_name') : 'All Alerts'

    return (<>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => updateIsModalOpen(false)}
                ariaHideApp={false}
                className="Modal acknowledge-modal">
                <>
                    {
                        isSingleAlertAcknowledgement ? <>
                            {getModalHeader(updateIsModalOpen, modalTitle)}
                            <div
                                className={'alert-retailer-label'}>{`Retailer ${retailerDisplay(retailerId, getRetailerName(retailerId))}`}</div>
                            <div className={'alert-modal-task-name'}>{taskName}</div>
                        </> : <>
                            {getModalHeader(updateIsModalOpen, 'Acknowledge Multiple Alerts')}
                            <div className={'alert-count'}>{`${insertIds.length} alerts to acknowledge`}</div>
                            <div className={'alert-modal-task-name'}>{taskNameForAcknowledgeAll}</div>
                        </>
                    }
                    <br/>
                    <h2>Details</h2>
                    <div className={'editor-and-buttons-side-by-side-container'}>
                        <AceEditor
                            mode="json"
                            theme="github"
                            name="json_payload_ace"
                            editorProps={{$blockScrolling: true}}
                            value={prettyFormatJsonString(alertDetailsValue)}
                            readOnly={true}
                        />
                        <div className={'modal-component-divider'}></div>
                        <div className={'buttons-container'}>
                        <div><span className={'section-header'}>Is this a data issue?</span></div>

                        <RadioButtonGroup
                            callback={(dataIssue) => {
                                updateIsDataIssue(dataIssue === 'Further Review' ? null : dataIssue === 'Data Issue')
                            }}
                            selectedValue={isDataIssue === null ? 'Further Review' : isDataIssue ? 'Data Issue' : 'Not an issue'}
                            radioButtonOptions={['Data Issue', 'Not an issue', 'Further Review']}
                            className={'modal-radio-button-group-container'}
                            displayCheckIcon={true}
                        />
                        <div>
                            <span className={'section-header'}>Notes</span>
                            <textarea
                                title={'Alert Notes'}
                                placeholder={'What actions were taken to check this alert?'}
                                className={"modal-alerts-text-area-wide"}
                                value={localNotes}
                                onChange={e => updateLocalNotes(e.target.value)}
                            />
                        </div>
                    </div>
                    </div>
                </>
                <div className={"right-things"}>
                    <button className="button cancel modal-button" type="button" name="close"
                            onClick={() => updateIsModalOpen(false)}>
                        Cancel
                    </button>

                    <ButtonLoader buttonTitle={buttonTitle}
                                  buttonClassName={"button primary modal-button"}
                                  axiosFunction={makeApiCallPost}
                                  url={`${process.env.REACT_APP_EDP_API_BASE_URL}/updateAlerts/`}
                                  disabled={!acknowledgeButtonEnabled}
                                  postBody={{
                                      alertIds: insertIds,
                                      notes: localNotes,
                                      isDataIssue: isDataIssue,
                                      acknowledged: !acknowledged
                                  }}
                                  toastTextSuccess={toastTextSuccess}
                                  toastTextFailure={toastTextFailure}
                                  onSubmitCallback={() => {
                                      updateIsModalOpen(false);
                                      onSubmitCallback();
                                  }}
                    />
                </div>
            </Modal>
        </>
    );
}

AcknowledgeAlertModal.propTypes = {
    buttonTitle: PropTypes.string,
    insertIds: PropTypes.arrayOf(PropTypes.string),
    toastTextSuccess: PropTypes.string,
    toastTextFailure: PropTypes.string,
    onSubmitCallback: PropTypes.func,
    retailerId: PropTypes.string,
    taskName: PropTypes.string,
    falsePositive: PropTypes.any,
    initialNotes: PropTypes.string,
    acknowledged: PropTypes.bool,
};

export default AcknowledgeAlertModal