import _ from "lodash";

export function addLabelToOptionsIfNotExist(incomingOptions) {
    return incomingOptions.map(x => {
            if (typeof (x) === "string" || typeof (x) === "number")
                return {value: x, label: x}
            if (typeof (x) === "object" && x.label === undefined && x.value !== undefined)
                return {value: x.value, label: x.value}
            return x
        }
    )
}
export function wrapValueInOptionStructure(value){
    return !_.isUndefined(value) ? {value: value, label: value } : undefined;
}
export function wrapValueLabelInOptionStructure(value, label){
    return !_.isUndefined(value) ? {value: value, label: label } : undefined;
}

export function getValueFromOptions(selectedValue, dropDownValues) {
    return dropDownValues ?  dropDownValues.find(option => option.value === selectedValue) : undefined
}