import React, {useEffect, useState} from "react";
import {makeApiCall} from "../../Api/makeApiCall";
import Page from "../Components/Page";
import _ from "lodash";
import BucketPrice from "./BucketPrice";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import {GenericCostDisplay} from './GenericCostDisplay';

const FILES = [
    {filePath: 'costs_by_service.jsonl', fileType: 'jsonl', header: 'Overall Service Cost', Component: GenericCostDisplay},
    {filePath: 'trino_cluster.jsonl', header: 'Trino Cluster vs Transient Dataproc', Component: GenericCostDisplay},
    {filePath: 'transient_clusters_by_dag.jsonl', header: 'Transient Cluster Cost by Dag', Component: GenericCostDisplay},
    {filePath: 'dataflow_by_dag.jsonl', header: 'Dataflow Cost By Dag', Component: GenericCostDisplay},
    {filePath: 'checkpoint_bucket.json', header: 'Checkpoint Bucket Partitions', Component: BucketPrice},
    {filePath: 'data_bucket.json', header: 'Data Bucket Partitions', Component: BucketPrice},
    {filePath: 'data_test_bucket.json', header: 'Data Test Bucket Partitions', Component: BucketPrice},
    {filePath: 'tmp_bucket.json', header: 'Tmp Bucket Partitions', Component: BucketPrice}
];

const StoragePricePage = () => {
    const [costsResponse, setCostsResponse] = useState({});

    useEffect(() => {
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/gcp_costs`)
            .then(data => {
                setCostsResponse(data);
            });
    }, []);

    return (
        <Page name={"GCP Costs"}>
            <ol className="toc-list">
                {
                    FILES.map(f => (
                        <li key={f.filePath}>
                            <a href={`#${f.filePath}`}>
                                {f.header}
                            </a>
                        </li>
                    ))
                }
            </ol>
            <LoadingWrapper loading={_.isEmpty(costsResponse)}>
                {
                    Object.values(FILES).map(({Component, header, filePath}) => {
                        return <Component key={filePath} filePath={filePath} fileContents={costsResponse[filePath]} header={header}/>;
                    })
                }
            </LoadingWrapper>
        </Page>
    );
};

export default StoragePricePage;