import React, {useEffect, useState} from 'react';
import _ from "lodash"
import {getMoment} from "../../utils/time";
import moment from "moment";
import {SelectableList} from "../Components/SelectableList";
import Checkbox from "../Components/Checkbox";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import LoadingSpinner from "../Components/LoadingSpinner";
import {isOnboarding} from '../IngestionLogs/Columns/RetailerIdWithOnboarding';

function _getTaskCounts(apiResponse, filteredItems) {
    const taskCounts = apiResponse.taskTypes.map(taskNameMapping => {
        const taskName = taskNameMapping.alertId
        return ({
            label: taskNameMapping.displayName,
            value: filteredItems.filter(alert => alert.task_name === taskName).length
        });
    })
    return _.orderBy(taskCounts, ['value', 'label'], ['desc', 'asc'])
}

function _getRetailerCounts(filteredData, salesforceRetailerLookup) {
    const alertsByRetailer = _.groupBy(filteredData, alert => alert.processedPayload?.retailer_id);
    const retailerCounts = Object.entries(alertsByRetailer).map(([retailerId, alerts]) => {
        const onboardingPrefix = isOnboarding(salesforceRetailerLookup[retailerId]) ? `Onboarding: ` : '';
        return ({
            label: `${onboardingPrefix}${retailerId} - ${alerts[0].retailer_chain || ''}`,
            value: alerts.length,
            retailerId
        });
    });
    return _.orderBy(retailerCounts, ['value', 'label'], ['desc', 'asc'])
}

function _getDateCounts(filteredData) {
    const todayStartOfDay = getMoment().startOf('day')
    return [0, 1, 2, 3, 4, 5, 6]
        .map(daysToSubtract => todayStartOfDay.clone().subtract(daysToSubtract, "days"))
        .map(dateStartOfDay => ({
            label: dateStartOfDay.format('YYYY-MM-DD'),
            value: filteredData.filter(alert => _isSameDay(moment(alert.timestamp), dateStartOfDay)).length
        }))
}

function _isSameDay(moment, momentStartOfDay) {
    const hoursDiff = moment.diff(momentStartOfDay, 'hours')
    return hoursDiff >= 0 && hoursDiff < 24
}

function filterRetailers(salesforceRetailerLookup, includeOnlyActive, includeOnlyOnboarding, alert) {
    const retailerMapNotLoaded = salesforceRetailerLookup === undefined;
    if (retailerMapNotLoaded) {
        return false;
    }

    const anyFiltersSelected = includeOnlyActive || includeOnlyOnboarding;
    if (!anyFiltersSelected) {
        return true;
    }

    const salesforceRetailer = salesforceRetailerLookup[alert.processedPayload?.retailer_id];

    return (includeOnlyActive && salesforceRetailer?.active) || (includeOnlyOnboarding && isOnboarding(salesforceRetailer))
}

export const OpsAlertsSummaryContent = ({data}) => {
    const [selectedDate, updateSelectedDate] = useState(undefined)
    const [selectedRetailer, updateSelectedRetailer] = useState(undefined)
    const [selectedTask, updateSelectedTask] = useState(undefined)
    const [includeOnlyActive, updateIncludeOnlyActive] = useState(false)
    const [includeOnlyOnboarding, updateIncludeOnlyOnboarding] = useState(false)
    const [salesforceRetailerLookup, setSalesforceRetailerLookup] = useState({})

    const filteredData = data.metadata.filter(alert => filterRetailers(salesforceRetailerLookup, includeOnlyActive, includeOnlyOnboarding, alert));

    const onSubmit = () => {
        let url = '/alerts/log?days=7'

        if (selectedDate) {
            url += `&timestamp=${selectedDate.label}`
        }

        if (selectedRetailer) {
            url += `&retailerId=${selectedRetailer.retailerId}`
        }

        if (selectedTask) {
            url += `&task_name=${selectedTask.label}`
        }

        window.location.assign(url);
    }

    useEffect(() => {
        const query = `
        query salesforceRetailers {
            salesforceRetailers {
                retailerId
                active
                onboardingStatus
            }
        }`;
        makeApolloClientCall(query).then(data => {
            setSalesforceRetailerLookup(_.keyBy(data.salesforceRetailers, 'retailerId'))
        });

    }, []);

    return (
        <> {_.isEmpty(salesforceRetailerLookup) ? <LoadingSpinner/> :
            <>
                <div className={"retailer-status-group"}>
                    <h5>Last 7 days summary</h5>
                    <div>
                        <div className={"retailer-status-label"}>Retailer Status</div>
                        <div id={"check-boxes"}>
                            <Checkbox
                                id={'active'}
                                labelText={'Active'}
                                onChange={(isActive) => {
                                    updateIncludeOnlyActive(isActive)
                                }}
                                checked={includeOnlyActive}/>
                            <Checkbox
                                id={'onboarding'}
                                labelText={'Onboarding'}
                                onChange={(isOnboarding) => {
                                    updateIncludeOnlyOnboarding(isOnboarding)
                                }}
                                checked={includeOnlyOnboarding}/>
                        </div>
                    </div>
                </div>
                <div className={'alert-summary'}>
                    <div className={'alert-group'} data-testid={'date_group'}>
                        <h6>Date</h6>
                        <SelectableList items={_getDateCounts(filteredData)} onItemSelected={updateSelectedDate}
                                        selected={selectedDate}/>
                    </div>
                    <div className={'alert-group'} data-testid={'retailer_group'}>
                        <h6>Retailer</h6>
                        <SelectableList items={_getRetailerCounts(filteredData, salesforceRetailerLookup)} onItemSelected={updateSelectedRetailer}
                                        selected={selectedRetailer}/>
                    </div>
                    <div className={'alert-group'} data-testid={'task_group'}>
                        <h6>Task</h6>
                        <SelectableList items={_getTaskCounts(data, filteredData)} onItemSelected={updateSelectedTask}
                                        selected={selectedTask}/>
                    </div>
                    <div className={'alert-group'}>
                        <div className='input-group-button fake-button-for-show'>
                            <input type="submit" className='button' value='Submit' onClick={onSubmit}/>
                        </div>
                    </div>
                </div>
            </>}
        </>)
};

OpsAlertsSummaryContent.defaultProps = {};

OpsAlertsSummaryContent.propTypes = {};