import AcknowledgeAlertModal from "./AcknowledgeAlertModal";
import React, {useState} from "react";
import IconImage, {Icons} from "../Components/IconImage";
import EditAlertModal from "./EditAlertModal";


const AlertActions = ({value}) => {
    const buttonTitle = value.acknowledged ? 'Unacknowledge' : 'Acknowledge'
    const {
        insertId, notes, updateAlertDataFunction, payload, retailerId, taskName,
        falsePositive, acknowledged, partitionTime
    } = value;

    const [isAcknowledgeAlertModalOpen, setIsAcknowledgeAlertModalOpen] = useState(false);
    const [isEditAlertModalOpen, setIsEditAlertModalOpen] = useState(false);

    const updateIsAcknowledgeAlertModalOpen = (isOpen) => {
        setIsAcknowledgeAlertModalOpen(isOpen);
    }
    const updateIsEditAlertModalOpen = (isOpen) => {
        partitionTime && setIsEditAlertModalOpen(isOpen);
    }

    return (
        <div className={'alert-actions'}>
            <div className={'alert-single-action'}>
                <div className={'open-acknowledge-alert-modal'}>
                    <button
                        className={'header-button acknowledge-alert-button'}
                        onClick={async () => {
                            updateIsAcknowledgeAlertModalOpen(true);
                        }}
                        data-testid={'open-acknowledge-alert-modal-button'}
                    >
                        {value.partitionTime ? (value?.acknowledged === true ?
                                <IconImage iconType={Icons.UNACKNOWLEDGED_ALERTS}/>
                                : <IconImage iconType={Icons.ACKNOWLEDGED_ALERTS}/>) :
                            <IconImage iconType={Icons.WARNING}/>}
                    </button>
                </div>
                <AcknowledgeAlertModal
                    isModalOpen={isAcknowledgeAlertModalOpen}
                    updateIsModalOpen={updateIsAcknowledgeAlertModalOpen}
                    retailerId={String(retailerId)}
                    taskName={taskName}
                    buttonTitle={buttonTitle}
                    insertIds={[insertId]}
                    initialNotes={notes}
                    toastTextSuccess={`Successfully ${buttonTitle.toLowerCase()}d alert with id ${insertId}`}
                    toastTextFailure={`Failed to ${buttonTitle.toLowerCase()} alert with id ${insertId}`}
                    onSubmitCallback={updateAlertDataFunction}
                    acknowledged={acknowledged}
                    falsePositive={falsePositive}
                    alertDetailsValue={payload}
                    acknowledgeButtonEnabled={partitionTime}
                />
            </div>
            <div className={'divider'}/>
            <div className={'alert-single-action'}>
                <div className={'open-edit-modal'}>
                    <button
                        className={'header-button edit-alert-button'}
                        onClick={async () => {
                            updateIsEditAlertModalOpen(true);
                        }}
                        data-testid={'open-edit-modal-button'}
                    >
                        {value.partitionTime ? <IconImage iconType={Icons.EDIT_ALERTS}/> :
                            <IconImage iconType={Icons.WARNING}/>}
                    </button>
                </div>
                <EditAlertModal
                    isModalOpen={isEditAlertModalOpen}
                    updateIsModalOpen={updateIsEditAlertModalOpen}
                    retailerId={String(retailerId)}
                    taskName={taskName}
                    buttonTitle={'Save'}
                    insertIds={[insertId]}
                    initialNotes={notes}
                    toastTextSuccess={`Successfully saved alert with id ${insertId}`}
                    toastTextFailure={`Failed to save alert with id ${insertId}`}
                    onSubmitCallback={updateAlertDataFunction}
                    acknowledged={acknowledged}
                    falsePositive={falsePositive}
                />
            </div>
        </div>
    )
}

export default AlertActions