import {Breadcrumbs} from "../../Components/Breadcrumbs";
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import Page from "../../Components/Page";
import {designationTypeLabels} from "./types";
import {toastError, toastSuccess} from "../../../utils/toast";
import {toast} from "react-toastify";
import {faIcons} from "../../../utils/iconConstants";
import Modal from "react-modal";
import {Controller, useForm} from "react-hook-form";
import {designationTypes} from "./Designations";
import IconImage, {Icons} from "../../Components/IconImage";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import {getSingleRowActionQueryParams} from "../../Components/ReactTable/helpers";
import ReactTable from "../../Components/ReactTable";
import RetailerIdAndName from "../../Components/RetailerIdAndName";
import {getRetailerName} from "../../../utils/retailerMapCache";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {SELECT_COLUMN} from "../../Components/ReactTable/CheckboxColumnComponent";
import {sortAscendingWithNullsLast} from "../../../utils/sorting";
import {redirectWithMessage, useRedirectMessage} from "../../../utils/redirectMessage";
import ConfirmationModal from "../../Components/ConfirmationModal";
import DesignationTable from "./DesignationTable";
import {getValidInitialTabIndexOrDefault, qsBuilderNoNils} from "./designationUtils";
import Checkbox from "../../Components/Checkbox";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import Files from "react-files";
import DeleteButton from "../../../utils/components/DeleteButton";
import DesignationBulkAssignStoresModal from "./DesignationBulkAssignStoresModal";
import NumberedTextAreaList from "./NumberedTextAreaList";
import Select from "react-select";
import {getValueFromOptions, wrapValueInOptionStructure} from "../../../utils/maybeAddLabelToOptions";
import SelectDropdown from "../../Components/Dropdowns/SelectDropdown";
import {useQueryParams} from '../../../utils/urlBuilder';


const DesignationDetails = () => {
    const params = useParams()
    const qsParams = useQueryParams(useLocation());
    const version = qsParams.get('version');
    const designationId = parseInt(params.designationId)
    const designationDetailsEndpointPostUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/designations`;
    const designationDeleteEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/deleteDesignation`;
    const [designationDetails, setDesignationDetails] = useState({});
    const [currentVersion, setCurrentVersion] = useState(version)
    const [designationDetailsModalVisible, setDesignationDetailsModalVisible] = useState(false)
    const [designationDeleteModalVisible, setDesignationDeleteModalVisible] = useState(false)
    const [deleteConfirmed, setDeleteConfirmed] = useState(false)
    const [savingDesignationDetails, setSavingDesignationDetails] = useState(false)
    const [unassignStoresModalVisible, setUnassignStoresModalVisible] = useState(false)
    const [bulkUploadFileSelectionModalVisible, setBulkUploadFileSelectionModalVisible] = useState(false)
    const [bulkUploadSelectedFile, setBulkUploadSelectedFile] = useState(undefined)
    const [bulkUploadIssues, setBulkUploadIssues] = useState(undefined)
    const [unassignDesignationsModalVisible, setUnassignDesignationsModalVisible] = useState(false)
    const [tabIndex, setTabIndex] = useState(getValidInitialTabIndexOrDefault());
    const [loading, setLoading] = useState(true);

    const {
        register: registerField,
        handleSubmit,
        reset: resetForm,
        formState: {errors},
        control
    } = useForm();

    useRedirectMessage();

    const storesCountText = (stores) => `${stores.length} ${stores.length === 1 ? "store" : "stores"}`
    const storesSkippedText = (stores) => stores.length === 1 ? "was skipped" : "were skipped"

    const loadDesignationDetails = async (designationVersion) => {
        setLoading(true);
        const apiEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/designationDetails/${designationId}?${qsBuilderNoNils({version: designationVersion})}`;
        const result = await makeApiCall(apiEndpointUrl);
        setDesignationDetails(result)
        setLoading(false);
        return result;
    }

    useEffect(() => {
        loadDesignationDetails().then(designationDetails => {
            if (!currentVersion) {
                setCurrentVersion(designationDetails['currentVersion']);
            }
        })
    }, []);


    const columns = [
        SELECT_COLUMN,
        {
            header: "Store",
            id: 'store',
            accessorKey: 'store',
            accessorFn: row => `${row.storeId} - ${row.storeName}`,
            filterFn: (row, columnId, filterValue) => {
                return !filterValue || Boolean(`${row.getValue(columnId).toLowerCase()}`.includes(filterValue.toLowerCase()));
            }
        },
        {
            header: "Store Tag",
            id: "storeTag",
            accessorKey: 'storeTag',
            filterFn: (row, columnId, filterValue) => {
                return !filterValue || Boolean(`${row.original.storeTag}`.includes(filterValue));
            }
        },
        {
            header: "Retailer",
            id: "retailer",
            accessorKey: 'retailer',
            cell: ({row}) => <RetailerIdAndName retailerId={row.original.retailerId}/>,
            filterFn: (row, columnId, filterValue) => {
                return !filterValue || Boolean(`${row.original.retailerId} - ${getRetailerName(row.original.retailerId).toLowerCase()}`.includes(filterValue.toLowerCase()));
            },
            sortDescFirst: false,
            sortingFn: (rowA, rowB) => {
                const rowBValue = rowB.original.retailerId;
                const rowAValue = rowA.original.retailerId;
                return sortAscendingWithNullsLast(rowAValue,rowBValue);
            }
        }
    ]

    const changeEditModalVisibility = (isModalVisible) => {
        resetForm(designationDetails.designation)
        setDesignationDetailsModalVisible(isModalVisible)
    }

    const changeDeleteModalVisibility = (isModalVisible) => {
        resetForm(designationDetails.designation)
        setDesignationDeleteModalVisible(isModalVisible)
    }

    const saveDesignationDetailsAndRefresh = (updatedDesignation) => {
        setSavingDesignationDetails(true)
        return makeApiCallPost(
            `${designationDetailsEndpointPostUrl}${getSingleRowActionQueryParams("update")}`,
            {...updatedDesignation, index: designationDetails.designation.index}
        )
            .then((response) => {
                if (response?.apiError) {
                    console.error(`error =============> ${JSON.stringify(response.apiError)}`);
                    toastError(toast, `Issue editing designation`)
                }
                else {
                    toastSuccess(toast, `Successfully edited designation: ${updatedDesignation.index} - ${updatedDesignation.name}`)
                }
            })
            .then(() => setDesignationDetailsModalVisible(false))
            .then(() => loadDesignationDetails())
            .then(() => setSavingDesignationDetails(false))
    }

    const deleteDesignationAndRedirect = () => {
        return makeApiCallPost(designationDeleteEndpointUrl, {"designationId": designationId})
            .then((response) => {
                if (response?.apiError) {
                    console.error(`error =============> ${JSON.stringify(response.apiError)}`);
                    toastError(toast, `Error deleting designation`)
                }
                else {
                    const message = `Successfully deleted ${designationName}.`;
                    const redirectUrl = `/designations`;
                    redirectWithMessage(message, redirectUrl);
                }
            })
    }

    const saveUnassignStoresAndRefresh = (selectedRows) => {
        setLoading(true)
        setUnassignStoresModalVisible(false)
        return makeApiCallPost(
            `${process.env.REACT_APP_EDP_API_BASE_URL}/designation/unassignStores`,
            {designationId: designationId, storeIds: selectedRows.map(row => row.original.storeId), version: currentVersion}
        ).then((response) => {
            if (response?.apiError) {
                console.error(`error =============> ${JSON.stringify(response.apiError)}`);
                toastError(toast, `Issue unassigning stores`)
                setLoading(false);
            }
        })
            .then(() => loadDesignationDetails())
    }

    const saveUnassignDesignationAndRefresh = (selectedRows) => {
        setLoading(true);
        setUnassignDesignationsModalVisible(false)
        //batman
        return makeApiCallPost(
            `${process.env.REACT_APP_EDP_API_BASE_URL}/designation/unassignDesignations`,
            {designationId: designationId, childDesignationIds: selectedRows.map(row => row.original.index), version: currentVersion}
        ).then((response) => {
            if (response?.apiError) {
                setLoading(false);
                console.error(`error =============> ${JSON.stringify(response.apiError)}`);
                toastError(toast, `Issue unassigning designations`)
            }
        })
            .then(() => loadDesignationDetails())
    }

    function getDesignationIdAndVersion() {
        return `${designationId}?${qsBuilderNoNils({version: currentVersion})}`;
    }

    const designationName = designationDetails?.designation?.name;
    return <>
        <LoadingWrapper loading={loading}>
            <Page
                name={`${designationDetails?.designation?.index} - ${designationName}`}
                secondaryLabel={`Type: ${designationTypeLabels[designationDetails?.designation?.type]}`}
                breadCrumbs={<Breadcrumbs items={[{label: "Services"},
                    {label: "Designations", to: "/designations"},
                    {label: `${designationName}`}]}
                />}
                headerSubComponent={<span className={'designation-header-component-container'}>
                    {currentVersion && <SelectDropdown
                        id={"version_dropdown"}
                        inputLabel={'Period Version'}
                        dataTestId={'version-select-dropdown-component-container'}
                        onChange={(option) => {
                            setCurrentVersion(option.value);
                            loadDesignationDetails(option.value);
                        }}
                        options={designationDetails.designationVersions?.map(wrapValueInOptionStructure)}
                        selectValue={currentVersion}
                        isClearable
                        placeholder={"Select Version..."}
                        />}

                    {currentVersion && <button className={'designation-copy-button'} data-testid={'designation-copy-button'}
                             title={'Copy Designation'}
                             onClick={() => {
                             }}>
                        <i data-testid={'designation-copy-button-icon'}
                           className={faIcons.copy}/>
                    </button>}
                    <button className={'designation-edit-button'} data-testid={'designation-edit-button'}
                            title={'Edit Designation'}
                            onClick={() => changeEditModalVisibility(true)}>
                        <i data-testid={'designation-edit-button-icon'}
                           className={faIcons.edit}/>
                    </button>
                    <div className="designation-header-component-divider"/>
                    <button className={'designation-delete-button'} data-testid={'designation-delete-button'}
                            title={'Delete Designation'}
                            onClick={() => changeDeleteModalVisibility(true)}>
                        <i data-testid={'designation-delete-button-icon'} className={faIcons.delete}/>
                    </button>
                    {designationDeleteModalVisible && <ConfirmationModal
                        confirmButtonClass={"danger-button"}
                        confirmButtonText={"Delete"}
                        headerText={`Delete designation`}
                        cancellationCallback={() => setDesignationDeleteModalVisible(false)}
                        confirmationCallback={deleteDesignationAndRedirect}
                        submitButtonDisabled={!deleteConfirmed}
                    >
                        {`There are ${designationDetails.stores.length} assigned stores and ${designationDetails.childDesignations.length} child designations linked to this. Delete `}<b>{designationName}</b>{`?`}
                        <div className={"line-break"} />
                        <Checkbox labelText={'This action cannot be undone.'}
                                  onChange={() => {
                                      setDeleteConfirmed(!deleteConfirmed);
                                  }}
                                  checked={deleteConfirmed}
                                  extraLabelClassName={'delete-confirmed-checkbox-label'}
                        />
                    </ConfirmationModal>}
                </span>}
            >
                <Tabs data-testid={"tabs"} selectedIndex={tabIndex}
                      onSelect={setTabIndex}
                >
                    <TabList>
                        <Tab>Stores</Tab>
                        <Tab>Child Designations</Tab>
                    </TabList>
                    <TabPanel>
                        <ReactTable
                            filterable={true}
                            data={designationDetails.stores}
                            columns={columns}
                        >
                            {
                                ({table}) => {
                                    const selectedRows = table.getSelectedRowModel().rows;
                                    return (
                                        <span className={'designation-action-buttons-container'} data-testid={"stores-action-button-container"}>
                                            <a type={"button"} className={"button primary"} href={`/designation/assignStore/${getDesignationIdAndVersion()}`}>Assign Store</a>
                                            <button type={'button'} aria-label={'upload'} onClick={() => {
                                                setBulkUploadSelectedFile(undefined)
                                                setBulkUploadFileSelectionModalVisible(true)
                                            }} className={"designation-action-button"} disabled={false}>
                                                <IconImage iconType={Icons.BATCH_ASSIGN_FROM_FILE_IMPORT}/>
                                            </button>
                                            <div className="designation-header-component-divider"/>
                                            <button type={'button'} aria-label={'un-assign'} onClick={() => setUnassignStoresModalVisible(true)} className={"designation-action-button"} disabled={!selectedRows?.length}>
                                                <IconImage iconType={Icons.UN_ASSIGN}/>
                                            </button>
                                            {unassignStoresModalVisible && <ConfirmationModal
                                                confirmButtonClass={"danger-button"}
                                                confirmButtonText={"Unassign"}
                                                headerText={`Unassign ${selectedRows.length === 1 ? 'Store' : 'Stores'}`}
                                                cancellationCallback={() => setUnassignStoresModalVisible(false)}
                                                confirmationCallback={() => saveUnassignStoresAndRefresh(selectedRows)}>
                                                {`Unassign ${selectedRows.length} ${selectedRows.length === 1 ? 'store' : 'stores'} from`}&nbsp;<b>{`${designationId} - ${designationName}`}</b>
                                            </ConfirmationModal>}
                                        </span>
                                    )
                                }
                            }
                        </ReactTable>
                        <DesignationBulkAssignStoresModal isOpen={bulkUploadFileSelectionModalVisible} onClose={() => setBulkUploadFileSelectionModalVisible(false)}>
                            <div>Prepare a list of store ids to assign to <b>{`${designationId} - ${designationName}`}</b>.</div>
                            <div>File should be in .csv format.</div>
                            <div>
                                {!bulkUploadSelectedFile && <Files
                                    className={"button"}
                                    onChange={(files) => {
                                        setBulkUploadSelectedFile((files[0]))
                                    }}

                                    accepts={['.csv']}
                                    multiple={false}
                                    maxFileSize={10*1024*1024}
                                    clickable
                                >
                                    Browse...
                                </Files>}
                                {bulkUploadSelectedFile && <div>
                                    <div className={'selected-file-box'}>
                                        <IconImage iconType={Icons.FILE_UPLOAD}/>
                                        <div>{bulkUploadSelectedFile.name}</div>
                                        <DeleteButton onDelete={() => setBulkUploadSelectedFile(undefined)}/>
                                    </div>
                                    <div className={'bottom-button-row'}>
                                        <button type={'button'} className={"cancel"} aria-label={'cancel'} onClick={() => setBulkUploadFileSelectionModalVisible(false)}>
                                            Cancel
                                        </button>
                                        <button type={'button'} className={'primary'} onClick={() => {
                                            const fileReader = new FileReader();

                                            fileReader.onloadend = (e) => {
                                                const content = e.target.result;
                                                const data = {storeIds: content, designationId, version: currentVersion};
                                                setLoading(true)
                                                setBulkUploadFileSelectionModalVisible(false)
                                                makeApiCallPost(`${process.env.REACT_APP_EDP_API_BASE_URL}/designation/bulkAssign`, data)
                                                    .then(async (response) => {
                                                        await loadDesignationDetails()
                                                        if (response.newAssigns?.length && !response.duplicates?.length && !response.invalids?.length) {
                                                            toastSuccess(toast, `Successfully batch assigned ${storesCountText(response.newAssigns)} to ${designationId} - ${designationName}`)
                                                        } else {
                                                            setBulkUploadIssues(response)
                                                        }
                                                    });
                                            }
                                            fileReader.readAsText(bulkUploadSelectedFile)
                                        }}>
                                            Import and Assign
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                        </DesignationBulkAssignStoresModal>
                        <DesignationBulkAssignStoresModal isOpen={bulkUploadIssues !== undefined} onClose={() => setBulkUploadIssues(undefined)}>
                            {bulkUploadIssues?.apiError ? <>
                                <div>That didn’t work. The system encountered an unexpected format in the file.</div>
                                <div>Check your CSV file and try uploading again.</div>
                            </> : <>
                                {bulkUploadIssues?.newAssigns?.length ? <>
                                    <div>{storesCountText(bulkUploadIssues.newAssigns)} successfully imported and assigned to <b>{designationId} - {designationName}</b>.</div>
                                </> : <></>}
                                {bulkUploadIssues?.duplicates?.length ? <>
                                    <div>{storesCountText(bulkUploadIssues.duplicates)} found already on the list {storesSkippedText(bulkUploadIssues.duplicates)}.</div>
                                    <NumberedTextAreaList values={bulkUploadIssues.duplicates}/>
                                </> : <></>}
                                {bulkUploadIssues?.invalids?.length ? <>
                                    <div>{storesCountText(bulkUploadIssues.invalids)} not found in the Store Service {storesSkippedText(bulkUploadIssues.invalids)}.</div>
                                    <NumberedTextAreaList values={bulkUploadIssues.invalids}/>
                                </> : <></>}
                            </>}
                            <div className={'bottom-button-row'}>
                                <button type={'button'} className={'close'} onClick={() => setBulkUploadIssues(undefined)}>Close</button>
                            </div>
                        </DesignationBulkAssignStoresModal>
                    </TabPanel>
                    <TabPanel>
                        <DesignationTable
                            designations={designationDetails.childDesignations}>
                            {
                                ({table}) => {
                                    const selectedRows = table.getSelectedRowModel().rows;
                                    return (
                                        <span className={'designation-action-buttons-container'} data-testid={"child-designation-action-button-container"}>
                                            <a className={"button primary"} style={{display: 'inline-block'}} href={`/designation/linkChildDesignations/${getDesignationIdAndVersion()}`}>Link Child</a>
                                            <button type={'button'} aria-label={'un-assign'} onClick={() => setUnassignDesignationsModalVisible(true)} className={"designation-action-button"} disabled={!selectedRows?.length}>
                                                <IconImage iconType={Icons.UN_ASSIGN}/>
                                            </button>
                                            {unassignDesignationsModalVisible && <ConfirmationModal
                                                confirmButtonClass={"danger-button"}
                                                confirmButtonText={"Unassign"}
                                                headerText={`Unassign ${selectedRows.length === 1 ? 'Child Designation' : 'Child Designations'}`}
                                                cancellationCallback={() => setUnassignDesignationsModalVisible(false)}
                                                confirmationCallback={() => saveUnassignDesignationAndRefresh(selectedRows)}>
                                                {`Unassign ${selectedRows.length} ${selectedRows.length === 1 ? 'child designation' : 'child designations'} from`}&nbsp;<b>{`${designationId} - ${designationDetails.designation.name}`}</b>
                                            </ConfirmationModal>}
                                        </span>
                                    )
                                }
                            }
                        </DesignationTable>
                    </TabPanel>
                    <Modal
                        clickable
                        isOpen={designationDetailsModalVisible}
                        className={"add-edit-modal"}
                        overlayClassName="Overlay"
                        ariaHideApp={false}
                        onRequestClose={() => changeEditModalVisibility(false)}
                    >
                        <div data-testid={'designation-details-modal'} className={'designation-settings'}>
                            <div className={"modal-header"}>
                                <h1>Edit Designation</h1>
                                <button type={'button'} aria-label={'close'} onClick={() => changeEditModalVisibility(false)}>
                                    <IconImage iconType={Icons.CLOSE}/>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit(saveDesignationDetailsAndRefresh)}>
                                <label htmlFor={'name'}>Designation Name*</label>
                                <input type={'text'} id={'name'}
                                       disabled={savingDesignationDetails}  {...registerField('name', {
                                    required: true
                                })}/>
                                {errors.name?.type === 'required' &&
                                    <div className={'error'}>Designation name is required.</div>}
                                <label htmlFor={'type'}>Designation Type*</label>
                                <Controller
                                    control={control}
                                    name="type"
                                    id="type"
                                    disabled={savingDesignationDetails}
                                    {...registerField('type', {required: true})}
                                    render={({field: {onChange, value, name, ref},}) => (
                                        <div data-testid={"designation-type-form-dropdown-component-container"}>
                                            <Select
                                                id={"designation_type_dropdown"}
                                                options={designationTypes}
                                                onChange={(option) => onChange(option?.value)}
                                                value={getValueFromOptions(value, designationTypes)}
                                                name={name}
                                                ref={ref}
                                            />
                                        </div>
                                    )}
                                />
                                <div className={'settings-header'}>Settings</div>
                                <div className={'checkbox-container'}>
                                    <label htmlFor={'is-versioned-checkbox'} className={'checkbox-label'}>Is
                                        Versioned</label>
                                    <input className={'checkbox'}
                                           type="checkbox"
                                           id={'is-versioned-checkbox'}
                                           readOnly
                                           disabled
                                           {...registerField('versioned', {required: false})}
                                    />
                                </div>
                                <div className={'modal-buttons'}>
                                    <button type={"button"} className={"button cancel"}
                                            disabled={savingDesignationDetails}
                                            onClick={() => changeEditModalVisibility(false)}>Cancel
                                    </button>
                                    <button type={"submit"} className={"button primary"}
                                            disabled={savingDesignationDetails}>Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </Tabs>
            </Page>
        </LoadingWrapper>
    </>
}

export default DesignationDetails;
