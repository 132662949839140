import React, {useEffect, useState} from 'react';
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import {useParams} from "react-router-dom";
import Page from "../Components/Page";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {ItemsTab} from "./Tabs/ItemsTab";
import {FilesTab} from "./Tabs/FilesTab";
import {ConfigurationsTab} from "./Tabs/ConfigurationsTab";
import {AlertTab} from "./Tabs/AlertsTab";
import RetailerFill from "./Tabs/RetailerFill";
import {CompositionTab} from "./Tabs/CompositionTab";
import {LogsTab} from "./Tabs/LogsTab";
import {makeApiCall} from "../../Api/makeApiCall";
import {FtpTab} from "./Tabs/FtpTab";
import GcsDataTab from "./Tabs/GcsDataTab";
import {AuditTab} from "./Tabs/AuditTab";
import {StoresTab} from "./Tabs/StoresTab";
import {ActionsTab} from "./Tabs/ActionsTab";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import RetailerInfoHeaderSection from "./RetailerInfoHeaderSection";

const retailerGqlQuery = (retailerId) => `
        query retailers {
            retailers(retailerId: ${retailerId}) {
                retailerId,
                retailTag,
                retailChain,
                retailerType,
                rollUpDayOfWeek,
                svcAcvInflationFactor,
                scvGrowthFactor,
                isFresh
            },
            salesforceRetailers(retailerIds: ${retailerId}){
                active,
                retailerAssociation
                companyType
                marketDesignation
            }
        }`;
const RetailerManagementPage = () => {
    const params = useParams();
    const retailerId = parseInt(params.retailerId)
    const [loading, updateLoading] = useState(true);
    const [retailerInformation, setRetailerInformation] = useState({})
    const [salesforceRetailers, setSalesforceRetailers] = useState({})
    const [retailerFtps, setRetailerFtps] = useState([])

    useEffect(() => {
        updateLoading(true)
        Promise.all([
                makeApolloClientCall(retailerGqlQuery(retailerId)),
                makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/ftpToRetailerIdDecode/`)
            ]
        ).then(([{retailers, salesforceRetailers}, ftpToRetailerIdDecode]) => {
                const salesforceRetailerExists = salesforceRetailers && salesforceRetailers.length > 0;
                setRetailerInformation(retailers[0])
                salesforceRetailers.map((retailer) => {
                    retailer.active = salesforceRetailerExists && retailer.active
                    retailer.association = salesforceRetailerExists && retailer.retailerAssociation;
                    retailer.companyType = salesforceRetailerExists ? retailer.companyType : undefined;
                    retailer.marketDesignation = salesforceRetailerExists ? retailer.marketDesignation : undefined;
                })
                setSalesforceRetailers(salesforceRetailers[0] || {})

                const ftps = ftpToRetailerIdDecode.data
                    .filter(retailer => retailer.retailerId === retailerId)
                    .map((result) => result.name)
                    .sort();
                setRetailerFtps(ftps);
            }
        ).finally(() => updateLoading(false))

    }, [retailerId]);

    return (
        <>
            <LoadingWrapper loading={loading}>
                <Page name={`${retailerId} - ${retailerInformation.retailChain}`}>
                    <RetailerInfoHeaderSection
                        retailerInformation={retailerInformation}
                        salesforceRetailers={salesforceRetailers}
                        ftps={retailerFtps}
                    />
                    <Tabs>
                        <TabList>
                            <Tab data-testid={'actions-tab-name'}>Actions</Tab>
                            <Tab data-testid={'stores-tab-name'}>Stores</Tab>
                            <Tab data-testid={'items-tab-name'}>Items</Tab>
                            <Tab data-testid={'ftp-tab-name'}>FTP</Tab>
                            <Tab data-testid={'logs-tab-name'}>Logs</Tab>
                            <Tab data-testid={'data-tab-name'}>Data</Tab>
                            <Tab data-testid={'configurations-tab-name'}>Configurations</Tab>
                            <Tab data-testid={'audit-tab-name'}>Audit</Tab>
                            <Tab data-testid={'alerts-tab-name'}>Alerts</Tab>
                            <Tab data-testid={'composition-tab-name'}>Composition</Tab>
                            <Tab data-testid={'files-tab-name'}>Files</Tab>
                            <Tab data-testid={'fill-tab-name'}>Fill</Tab>
                        </TabList>
                        <TabPanel data-testid={'actions-tab-panel'}>
                            <ActionsTab
                                active={salesforceRetailers.active}
                                retailChain={retailerInformation.retailChain}
                                retailerId={retailerId}
                            />
                        </TabPanel>
                        <TabPanel data-testid={'stores-tab-panel'}>
                            <StoresTab retailerId={retailerId} retailChain={retailerInformation.retailChain}/>
                        </TabPanel>
                        <TabPanel data-testid={'items-tab-panel'}>
                            <ItemsTab retailerId={retailerId}/>
                        </TabPanel>
                        <TabPanel data-testid={'ftp-tab-panel'}>
                            <FtpTab ftps={retailerFtps} retailerId={retailerId}/>
                        </TabPanel>
                        <TabPanel data-testid={'logs-tab-panel'}>
                            <LogsTab retailerId={retailerId}/>
                        </TabPanel>
                        <TabPanel data-testid={'data-tab-panel'}>
                            <GcsDataTab retailerId={retailerId} ftps={retailerFtps} />
                        </TabPanel>
                        <TabPanel data-testid={'configurations-tab-panel'}>
                            <ConfigurationsTab retailerId={retailerId}/>
                        </TabPanel>
                        <TabPanel data-testid={'audit-tab-panel'}>
                            <AuditTab retailerId={retailerId} ftps={retailerFtps}/>
                        </TabPanel>
                        <TabPanel data-testid={'alerts-tab-panel'}>
                            <AlertTab retailerId={retailerId}/>
                        </TabPanel>
                        <TabPanel data-testid={'composition-tab-panel'}>
                            <CompositionTab retailerId={retailerId}
                                            companyType={salesforceRetailers.companyType}
                                            marketDesignation={salesforceRetailers.marketDesignation}/>
                        </TabPanel>
                        <TabPanel data-testid={'files-tab-panel'}>
                            <FilesTab retailerId={retailerId}/>
                        </TabPanel>
                        <TabPanel data-testid={'fill-tab-panel'}>
                            <RetailerFill retailerId={retailerId}
                                          rollUpDayOfWeek={retailerInformation.rollUpDayOfWeek}/>
                        </TabPanel>
                    </Tabs>
                </Page>
            </LoadingWrapper>
        </>
    )

};

export default RetailerManagementPage;