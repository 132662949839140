import React, {useEffect, useState} from 'react';
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import EditableReactTableV2 from "../../Components/ReactTable/EditableReactTableV2";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import {makeApiCall} from "../../../Api/makeApiCall";
import _ from "lodash";
import {
    EditFormBooleanDropdownInputField,
    EditFormDropdownInputField,
    EditFormLabelErrorWrapper,
    EditFormNumberInputField,
    EditFormTextInputField
} from "../../Components/ReactTable/EditRecordForm";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import IconDropdown from "../../Components/IconDropdown";
import MarketServiceNavLinks from "../../Components/MarketServiceNavLinks";
import {findDuplicates} from "../../../utils/util";
import PropTypes from "prop-types";
import StoreInfoModal from "../StoreInfoModal";

export function EwbEditField({id, form}) {
    const retailerCorporateTotals = form.watch("retailer_corporate_totals")
    form.setValue(id, retailerCorporateTotals ? 'Yes' : '')
    return (
        <EditFormLabelErrorWrapper id={id} form={form} label={'EWB'}>
            <input
                type={"text"}
                id={id}
                disabled={true}
                readOnly={true}
                step={"any"}
                {...(form.register(id))}/>
        </EditFormLabelErrorWrapper>
    );
}

const MarketServiceContent = ({period}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/markets?period=${period}`;
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({
        endpointUrl,
        urlContainsParams: true
    });

    const [outletsDropdown, setOutletsDropdown] = useState([]);
    const [accountDropdown, setAccountDropdown] = useState([]);
    const [legacyAccountTypeDropdown, setLegacyAccountTypeDropdown] = useState([]);
    const [geographyLevelDropdown, setGeographyLevelDropdown] = useState([]);
    const [accountTypeDropdown, setAccountTypeDropdown] = useState([]);
    const [geographyTypeDropdown, setGeographyTypeDropdown] = useState([]);
    const [geographyCompositionDropdown, setGeographyCompositionDropdown] = useState([]);
    const [releasabilityDropdown, setReleasabilityDropdown] = useState([]);
    const [measureAvailabilityDropdown, setMeasureAvailabilityDropdown] = useState([]);
    const [marketStoresByPeriod, setMarketStoresByPeriod] = useState([]);
    const [marketStores, setMarketStores] = useState({});
    const [storeInfoModal, setStoreInfoModal] = useState(false);
    const [decodeTablesLoading, setDecodeTablesLoading] = useState(true);

    function IndexEditField({id, form}) {
        const formRegisterOptions = {required: true, valueAsNumber: true};
        const indexValue = form.getValues('index');
        if (indexValue === null) {
            const maxIndex = _.max(data.data.map(x => x.index));
            form.setValue(id, maxIndex + 1);
        }

        return (
            <EditFormLabelErrorWrapper id={id} form={form} label={"Market Id"} isRequired>
                <input
                    type={"text"}
                    id={id}
                    disabled={true}
                    readOnly={true}
                    step={"any"}
                    {...(form.register(id, formRegisterOptions))}/>
            </EditFormLabelErrorWrapper>
        );
    }

    function getCellForService(value, dropdownValues) {
        const displayValue = _.find(dropdownValues, {value: value})
        return <div>{displayValue?.label || value} </div>
    }

    function getServiceDropdownOptions(serviceValues) {
        return serviceValues.data.map(value => {
            return {label: value.name, value: value.index}
        });
    }

    function getFilterValueForService(row, columnIds, filterValue, dropdownValues) {
        const recordValue = row.original[columnIds];
        const label = _.find(dropdownValues, {value: recordValue})?.label
        return !filterValue || Boolean(`${recordValue} ${label?.toLowerCase()}`.includes(filterValue.toLowerCase()));
    }

    const marketServiceColumns = [
        {
            header: "Market Tag",
            sticky: "left",
            size: 120,
            accessorKey: "market_key",
            filterable: true,
            cell: ({getValue}) => {
                return <div data-testid={'market_key_value'} onClick={() => {
                    const storeIds = marketStoresByPeriod
                        .filter(x => parseInt(x.market_key) === getValue())
                        .map(x => x.store_list.map(x => x.store_id));
                    setMarketStores({marketKey: getValue(), storeIds});
                    toggleStoreInfoModal()
                }}><a className="number" href="#">{getValue()}</a></div>;
            },
        },
        {
            header: "Market Id",
            sticky: "left",
            size: 120,
            accessorKey: "index",
            filterable: true,
        },
        {
            header: "Description",
            sticky: "left",
            size: 460,
            accessorKey: "description",
            filterable: true,
        },
        {
            header: "Order No",
            accessorKey: "order_no",
            filterable: true,
        },
        {
            header: "EWB",
            accessorKey: "ewb",
            filterable: true,
        },
        {
            header: "Account",
            accessorKey: "account_id",
            filterable: true,
            size: 200,
            cell: ({getValue}) => getCellForService(getValue(), accountDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, accountDropdown),
        },
        {
            header: "Outlet",
            accessorKey: "outlets_id",
            filterable: true,
            size: 225,
            cell: ({getValue}) => getCellForService(getValue(), outletsDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, outletsDropdown),
        },
        {
            header: "Geo Lvl",
            accessorKey: "geography_level_id",
            filterable: true,
            cell: ({getValue}) => getCellForService(getValue(), geographyLevelDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, geographyLevelDropdown),
        },
        {
            header: "Geography Type",
            accessorKey: "geography_type_id",
            filterable: true,
            cell: ({getValue}) => getCellForService(getValue(), geographyTypeDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, geographyTypeDropdown),
        },
        {
            header: "Legacy Acct Type",
            accessorKey: "legacy_account_type_id",
            filterable: true,
            cell: ({getValue}) => getCellForService(getValue(), legacyAccountTypeDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, legacyAccountTypeDropdown),
        },
        {
            header: "Acct Type",
            accessorKey: "account_type_id",
            filterable: true,
            cell: ({getValue}) => getCellForService(getValue(), accountTypeDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, accountTypeDropdown),
        },
        {
            header: "Releasability",
            accessorKey: "releasability_id",
            filterable: true,
            cell: ({getValue}) => getCellForService(getValue(), releasabilityDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, releasabilityDropdown),
        },
        {
            header: "Measure Avail",
            accessorKey: "measure_availability_id",
            filterable: true,
            cell: ({getValue}) => getCellForService(getValue(), measureAvailabilityDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, measureAvailabilityDropdown),
        },
        {
            header: "Control Brand",
            accessorKey: "control_brand",
            filterable: true,
        },
        {
            header: "Geo Comp",
            accessorKey: "geography_composition_id",
            filterable: true,
            cell: ({getValue}) => getCellForService(getValue(), geographyCompositionDropdown),
            filterFn: (row, columnIds, filterValue) => getFilterValueForService(row, columnIds, filterValue, geographyCompositionDropdown),
        },
        {
            header: "IRI HiCone Market",
            accessorKey: "iri_hicone_market",
            filterable: true,
        },
        {
            header: "Retailer Corp Totals",
            accessorKey: "retailer_corporate_totals",
            filterable: true,
            size: 190,
        },
        {
            header: "Activate Dist Tracker",
            accessorKey: "activate_for_distribution_tracker",
            filterable: true,
            size: 190,
        },
        {
            header: "Market Group Id",
            accessorKey: "market_group_id",
            filterable: true,
        },
        {
            header: "Manu Geo Family Id",
            accessorKey: "manufacturer_geo_family_id",
            filterable: true,
            size: 190,
        },
        {
            header: "Retailer Geo Family Id",
            accessorKey: "retailer_geo_family_id",
            filterable: true,
            size: 190,
        },
        {
            header: "Geo Family Desc",
            accessorKey: "geo_family_description",
            filterable: true,
        },
        {
            header: "Dataset",
            accessorKey: "dataset",
            filterable: true,
        },
    ]

    useEffect(() => {
        setDecodeTablesLoading(true)
        const marketStoresByPeriodQuery = `
            {
                markets (periodAbbreviation: "${period}") {
                    market_key
                    store_list {
                        store_id
                    }
                }
            }
        `

        Promise.all([
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/outlets`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/accounts`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/legacyAccountType`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/geographyLevel`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/accountType`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/geographyType`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/geographyComposition`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/releasability`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/measureAvailability`),
            makeApolloClientCall(marketStoresByPeriodQuery),
        ])
            .then(([
                       outletsService,
                       accountService,
                       legacyAccountTypeService,
                       geographyLevelService,
                       accountTypeService,
                       geographyTypeService,
                       geographyCompositionService,
                       releasabilityService,
                       measureAvailabilityService,
                       marketStoresByPeriodResults
                   ]) => {
                setOutletsDropdown(getServiceDropdownOptions(outletsService));
                setAccountDropdown(getServiceDropdownOptions(accountService));
                setLegacyAccountTypeDropdown(getServiceDropdownOptions(legacyAccountTypeService));
                setGeographyLevelDropdown(getServiceDropdownOptions(geographyLevelService));

                setAccountTypeDropdown(getServiceDropdownOptions(accountTypeService));
                setGeographyTypeDropdown(getServiceDropdownOptions(geographyTypeService));
                setGeographyCompositionDropdown(getServiceDropdownOptions(geographyCompositionService));
                setReleasabilityDropdown(getServiceDropdownOptions(releasabilityService));
                setMeasureAvailabilityDropdown(getServiceDropdownOptions(measureAvailabilityService));

                setMarketStoresByPeriod(marketStoresByPeriodResults.markets)
                setDecodeTablesLoading(false)
            })
    }, []);

    async function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        delete newOrUpdatedRow.retailer_geo_family_id;
        delete newOrUpdatedRow.manufacturer_geo_family_id;
        delete newOrUpdatedRow.geo_family_description;
        Object.keys(newOrUpdatedRow).forEach(function (key) {
            if (newOrUpdatedRow[key] === undefined || newOrUpdatedRow[key] === "") {
                newOrUpdatedRow[key] = null;
            }
            if (isNaN(newOrUpdatedRow[key]) && !newOrUpdatedRow[key]) {
                newOrUpdatedRow[key] = null;
            }
        });

        await onRowEditConfirmed(newOrUpdatedRow);
    }

    function toggleStoreInfoModal() {
        setStoreInfoModal(!storeInfoModal)
    }

    function validatePreSave({form}) {
        const description = form.getValues('description');
        const errors = [
            {regex: new RegExp(/^\s|\s$/), message: 'Description cannot have leading or trailing whitespace'},
            {regex: new RegExp(/\s\s/), message: 'Description cannot contain multiple consecutive whitespaces'},
            {regex: new RegExp(/ /), message: 'Description contains a non breaking space'}
        ].filter(e => e.regex.test(description))
            .map(e => e.message)

        if (errors.length > 0) {
            form.setError('description', {
                type: 'custom',
                message: errors
            });
        }

        return errors.length === 0;
    }

    return (
        /*@formatter:off*/
        <div>
            <LoadingWrapper loading={loading || decodeTablesLoading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={marketServiceColumns}
                    initialState={{
                        pagination: {pageSize: 10},
                        sorting: [{id: "index", desc: true}]
                    }}
                    actionButtonsProps={{sticky: true}}
                    editTable={[
                        {id: 'index', Component: args => <IndexEditField {...args} />},
                        {id: 'market_key', Component: args => <EditFormNumberInputField {...args} isRequired/>},
                        {id: 'description', Component: args => <EditFormTextInputField {...args} isRequired/>},
                        {id: 'order_no', Component: args => <EditFormNumberInputField {...args}/>},
                        {id: 'account_id', Component: args => <EditFormDropdownInputField {...args} options={accountDropdown}/>},
                        {id: 'outlets_id', Component: args => <EditFormDropdownInputField {...args} options={outletsDropdown}/>},
                        {id: 'geography_level_id', Component: args => <EditFormDropdownInputField {...args} options={geographyLevelDropdown}/>},
                        {id: 'account_type_id', Component: args => <EditFormDropdownInputField {...args} options={accountTypeDropdown}/>},
                        {id: 'releasability_id', Component: args => <EditFormDropdownInputField {...args} options={releasabilityDropdown}/>},
                        {id: 'measure_availability_id', Component: args => <EditFormDropdownInputField {...args} options={measureAvailabilityDropdown}/>},
                        {id: 'geography_type_id', Component: args => <EditFormDropdownInputField {...args} options={geographyTypeDropdown}/>},
                        {id: 'ewb', Component: args => <EwbEditField {...args} />},
                        {id: 'legacy_account_type_id', Component: args => <EditFormDropdownInputField {...args} options={legacyAccountTypeDropdown}/>},
                        {id: 'geography_composition_id', Component: args => <EditFormDropdownInputField {...args} options={geographyCompositionDropdown}/>},
                        {id: 'manufacturer_geo_family_id', Component: args => <EditFormNumberInputField {...args} disabled />},
                        {id: 'retailer_geo_family_id', Component: args => <EditFormNumberInputField {...args} disabled />},
                        {id: 'geo_family_description', Component: args => <EditFormTextInputField {...args} disabled />},
                        {id: 'retailer_corporate_totals', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                        {id: 'market_group_id', Component: args => <EditFormTextInputField {...args} />},
                        {id: 'control_brand', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                        {id: 'iri_hicone_market', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                        {id: 'activate_for_distribution_tracker', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    ]}
                    editModalAdditionalClass={"market-service-edit-modal"}
                    onRowEditConfirmed={onRowEditConfirmedWrapper}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                    validatePreSave={validatePreSave}
                >
                    {
                        ({table}) => {
                            const rows = table.getCoreRowModel().rows.map(row => row.original);
                            const duplicateIndices = findDuplicates(rows, ['index']).index ?? {};
                            const duplicateMarketKeys = findDuplicates(rows, ['market_key']).market_key ?? {};

                            return (
                                <div>
                                    <div className={'market-service-content-controls'}>
                                        <IconDropdown iconName={'fa-cog'} title={"Services"}>
                                            <MarketServiceNavLinks period={period}/>
                                        </IconDropdown>
                                        <div>
                                            {(duplicateIndices.length > 0) &&
                                                <div className={'duplicate-warning-message'}>
                                                    {`Duplicated Market Ids: ${duplicateIndices}`}
                                                </div>
                                            }
                                            {(duplicateMarketKeys.length > 0) &&
                                                <div className={'duplicate-warning-message'}>
                                                    {`Duplicated Market Tags: ${duplicateMarketKeys}`}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    }
                </EditableReactTableV2>
                <StoreInfoModal closeModal={toggleStoreInfoModal} isOpen={storeInfoModal} storeInfo={marketStores.storeIds} marketKey={marketStores.marketKey} />
            </LoadingWrapper>
        </div>
        /*@formatter:on*/
    );
};

MarketServiceContent.propTypes = {
    period: PropTypes.string.isRequired
};

export default MarketServiceContent;