export const nestedRetailerManagementContent = {
    id: "retailer-management",
    menuItems: [
        {name: "All Retailers", url: "/retailerService"},
        {name: "Missing Retailers", url: "/missingRetailers"}]
}
export const nestedRetailerConfigurationsContent = {
    id: "configurations-retailers",
    menuItems: [
        {name: "Check Digit", url: "/checkDigitRetailers"},
        {name: "Priority: Store Insights", url: "/priorityRetailers/storeInsights"},
        {name: "Priority: Market Insights", url: "/priorityRetailers/marketInsights"},
        {name: "Transactional", url: "/transactionalRetailers"},
        {name: "Implied Decimal", url: "/impliedDecimalRetailers"},
        {name: "Calculated Sales Amount", url: "/calculatedSalesAmountRetailers"},
        {name: "Transactional Multi File", url: "/transactionalMultiFile"},
        {name: "Skip Fill", url: "/skipFillRetailers"},
        {name: "Skip Cleanse Rules", url: "/skipCleanseRules"},
        {name: "Receipt Lag", url: "/receiptLag"},
        {name: "Ingestion Paused", url: "/ingestionPausedRetailers"},
        {name: "Store Insights", url: "/storeInsightsIgnoredRetailers"},
        {name: "Minimum Days of Sales", url: "/minimumDaysOfSales"},
        {name: "Items Extract", url: "/itemsExtract"},
        {name: "Missing Data Exclusions", url: "/missingDataRetailerExclusions"},
        {name: "Fresh Retailers", url: '/freshRetailers'}
    ]
}
export const nestedStoreContent = {
    id: "configurations-stores",
    menuItems: [
        {name: "Store Closures", url: "/storeClosures"},
        {name: "Supplemental Stores", url: "/supplementalStoreConfig"},
        {name: "Skip", url: "/configurations/store/skip"},
        {name: "Banner", url: "/configurations/store/banner"},
        {name: "Missing Data Exclusions", url: "/configurations/store/missingDataExclusions"}
    ]
}
export const nestedOutliersContent = {
    id: 'alerts-outliers',
    menuItems: [
        {name: "Record Count", url: "/alerts/outliers/records"},
        {name: "Unit", url: "/alerts/outliers/salesUnits"},
        {name: "Sales Amount", url: "/alerts/outliers/salesAmount"}
    ]
}