import _ from 'lodash';

export const isNullOrUndefined = (value) => value === undefined || value === null
export const ifNotNullOrUndefined = (value, defaultValue) => isNullOrUndefined(value) ? defaultValue : value

export function getWindowLocation() {
    return window.location
}

export function getWindowStorage() {
    return window.localStorage
}

export function getUniqueFields(fields) {
    return fields.filter(field => 'isUniqueKey' in field && field['isUniqueKey'] === true)
}

export function isValidPeriodFormat(value) {
    return /^\d{4}-P\d{2}$/.test(value);
}


export function findDuplicates(data, fieldsToDeDup) {
    const finalReturn = {};
    const duplicateValues = {};
    const allValues = {};
    fieldsToDeDup.forEach(field => {
        duplicateValues[field] = {};
        allValues[field] = {};
        finalReturn[field] = [];
    });

    data.forEach(object => {
        // noinspection JSUnusedLocalSymbols
        Object.entries(object)
            .filter(([key, value]) => fieldsToDeDup.includes(key))
            .forEach(([key, value]) => {
                if (allValues[key][value]) {
                    duplicateValues[key][value] = true;
                }
                allValues[key][value] = true;
            })
    });
    Object.entries(duplicateValues)
        .forEach(([key, value]) => {
            finalReturn[key] = Object.keys(value);
        });

    return finalReturn;
}

export function createReadinessIssuesListItem(issue) {
    let issueDetail;
    if(issue.days){
        return <ul className={'missing-dates-list'}>
            <li>{`${_.startCase(issue.issueType)}: ${issue.week.endDate} (${issue.days.length}/7)`}
                <ul className={'missing-dates-days-list'}>
                    {issue.days.map(day => <li className={'missing-dates-days-list-item'}>{day}</li>)}
                </ul>
            </li>

        </ul>
    } else if (issue.week) {
        issueDetail = issue.week.endDate
    } else if (issue.message) {
        issueDetail = issue.message;
    } else {
        issueDetail = issue.count;
    }

    const extraDetail = issueDetail ? `: ${issueDetail}` : ""
    return `${_.startCase(issue.issueType)}${extraDetail}`;
}


export function nullSafePercentage(numerator, denominator) {
    let percentage = 1
    if (numerator && denominator) {
        percentage = 1 - (numerator / denominator);
    }
    return _.round((percentage * 100));
}
