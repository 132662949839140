import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getMoment} from "../../utils/time";
import {makeApiCall} from "../../Api/makeApiCall";
import {AcknowledgeAll} from "./AcknowledgeAll";
import ReactTable from "../Components/ReactTable";
import {getStringParamOrDefault} from "../../utils/urlBuilder";
import {getOpsAlertColumns} from "./opsAlertsContentConstants";
import JiraLoginButton from "./JiraLoginButton";
import ShowAcknowledgedAlertsCheckbox from "./ShowAcknowledgedAlertsCheckbox";
import DataIssueFilterCheckboxes from "./DataIssueFilterCheckboxes";
import _ from "lodash";

export const isAlertTaskLinkable = (task) => {
    switch (task) {
        case 'all_records_rejected':
        case 'only_unmapped_stores_found':
            return true

        default:
            return false
    }
}

function parsePayload(data) {
    try {
        let result = JSON.parse(data.payload);
        if (Array.isArray(result) && data.task_name === 'retailers_always_fill_failed') {
            return {"retailerIds": [...new Set(result.map(t => t.retailerId))]}
        }
        return result;
    } catch (e) {
        return data;
    }
}

function getDataIssueClassName(rowInfo) {
    if (rowInfo?.original.acknowledged === true) {
        if (rowInfo?.original.falsePositive === false) {
            return 'data-issue-border'
        }

        if (rowInfo?.original.falsePositive === true) {
            return 'no-data-issue-border'
        }

        if (rowInfo?.original.falsePositive === null) {
            return 'further-review-border'
        }
    }

    return ''
}

export function filterAlertData(data, showAcknowledgedAlerts, showDataIssue, notDataIssue, furtherReview) {
    return data ? data.filter(record => {
        let keepRecord = true
        if (showAcknowledgedAlerts === true && record.acknowledged === true) {
            const isDataIssue = showDataIssue === true && record.falsePositive === false;
            const isNotDataIssue = notDataIssue === true && record.falsePositive === true;
            const isFalsePositive = furtherReview === true && record.falsePositive === null;
            const noFilter = (!showDataIssue && !notDataIssue && !furtherReview)
            keepRecord = (isDataIssue || isNotDataIssue || isFalsePositive || noFilter)
        }
        return keepRecord
    }) : data;
}

class OpsAlertsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            isModalOpen: false,
            showDataIssue: false,
            notDataIssue: false,
            furtherReview: false,
            filteredData: []
        }
        this.updateAlertData = this.updateAlertData.bind(this);
        this.getData = this.getData.bind(this);
        this.updateShowDataIssue = this.updateShowDataIssue.bind(this);
        this.updateNotDataIssue = this.updateNotDataIssue.bind(this);
        this.updateFurtherReview = this.updateFurtherReview.bind(this);
    }

    async updateAlertData() {
        makeApiCall(this.props.url)
            .then(async data => {
                await this.setState({data: data});
                this.applyFilters(this.state.showDataIssue,
                    this.state.notDataIssue,
                    this.state.furtherReview,
                    this.props.showAcknowledgedAlertsProps.showAcknowledgedAlerts,
                    this.getData());
            })
    }

    getData() {
        return this.state.data.metadata.map((data) => {
            const filteredAlert = _.filter(this.state.data.taskTypes, {alertId: data.task_name})
            const displayName = filteredAlert.length > 0 ? filteredAlert[0].displayName : data.task_name
            return (
                {
                    ...data,
                    task_name: displayName,
                    timestamp: getMoment(data.timestamp).format('YYYY-MM-DDThh:mm:ss.SSS Z'),
                    updateAlertDataFunction: this.updateAlertData,
                    ...(parsePayload(data))
                });
        });
    }

    async updateShowDataIssue(showDataIssue) {
        await this.setState({showDataIssue: showDataIssue})
        this.applyFilters(this.state.showDataIssue,
            this.state.notDataIssue,
            this.state.furtherReview,
            this.props.showAcknowledgedAlertsProps.showAcknowledgedAlerts,
            this.getData());
    }

    async updateNotDataIssue(notDataIssue) {
        await this.setState({notDataIssue: notDataIssue})
        this.applyFilters(this.state.showDataIssue,
            this.state.notDataIssue,
            this.state.furtherReview,
            this.props.showAcknowledgedAlertsProps.showAcknowledgedAlerts,
            this.getData());
    }

    async updateFurtherReview(furtherReview) {
        await this.setState({furtherReview: furtherReview})
        this.applyFilters(this.state.showDataIssue,
            this.state.notDataIssue,
            this.state.furtherReview,
            this.props.showAcknowledgedAlertsProps.showAcknowledgedAlerts,
            this.getData());
    }

    applyFilters(showDataIssue, notDataIssue, furtherReview, showAcknowledgedAlerts, data) {
        const filter = filterAlertData(data, showAcknowledgedAlerts, showDataIssue, notDataIssue, furtherReview);
        this.setState({filteredData: filter})
    }

    componentDidMount() {
        this.applyFilters(this.state.showDataIssue,
            this.state.notDataIssue,
            this.state.furtherReview,
            this.props.showAcknowledgedAlertsProps.showAcknowledgedAlerts,
            this.getData());
    }

    render() {
        const {updateShowAcknowledgedAlerts, showAcknowledgedAlerts} = this.props.showAcknowledgedAlertsProps;

        function getFilters() {
            // if trying to refactor, see commit first
            return [
                {
                    id: "retailer_id",
                    value: getStringParamOrDefault('retailerId', '')
                },
                {
                    id: "file",
                    value: getStringParamOrDefault('file', '')
                },
                {
                    id: "timestamp",
                    value: getStringParamOrDefault('timestamp', '')
                },
                {
                    id: "task_name",
                    value: getStringParamOrDefault('task_name', '')
                }
            ];
        }

        return <div className={'ops-alerts-content'}>
            <ReactTable
                filterable
                initialState={{columnFilters: getFilters()}}
                data={this.state.filteredData}
                columns={getOpsAlertColumns(this.state.data.taskTypes.map(tt => tt.displayName))}
                getTrProps={(rowInfo) => {
                    const alertAcknowledgeClassName = rowInfo?.original?.acknowledged === true ? 'alert-acknowledged' : '';
                    const dataIssueClassName = getDataIssueClassName(rowInfo);

                    const className = `${alertAcknowledgeClassName} ${dataIssueClassName}`.trim();

                    return {className};
                }}
            >
                {({rows}) => {
                    return (
                        <div className={'alerts-selections'}>
                            {this.props.showAcknowledgedAlerts && <ShowAcknowledgedAlertsCheckbox
                                updateShowAcknowledgedAlerts={updateShowAcknowledgedAlerts}
                                showAcknowledgedAlerts={showAcknowledgedAlerts}
                            />}
                            {showAcknowledgedAlerts && <DataIssueFilterCheckboxes
                                updateShowDataIssueAlerts={this.updateShowDataIssue}
                                showDataIssueAlerts={this.state.showDataIssue}
                                updateNotDataIssueAlerts={this.updateNotDataIssue}
                                notDataIssueAlerts={this.state.notDataIssue}
                                updateFurtherReviewAlerts={this.updateFurtherReview}
                                furtherReviewAlerts={this.state.furtherReview}
                            />}
                            <div className={this.props.showAcknowledgedAlerts ? 'alerts-divider' : ''}>
                                <AcknowledgeAll data={rows} onSubmitCallback={this.updateAlertData}/>
                            </div>
                            {this.props.showJiraLoginButton && <JiraLoginButton/>}
                        </div>
                    )
                }}
            </ReactTable>
        </div>;
    }
}

OpsAlertsContent.defaultProps = {
    showAcknowledgedAlerts: false,
    showJiraLoginButton: false,
    showAcknowledgedAlertsProps: {showAcknowledgedAlerts: false, updateShowAcknowledgedAlerts: () => null}
};

OpsAlertsContent.propTypes = {
    data: PropTypes.shape({
        metadata: PropTypes.array,
        taskTypes: PropTypes.arrayOf(PropTypes.object)
    }),
    showAcknowledgedAlerts: PropTypes.bool,
    showAcknowledgedAlertsProps: PropTypes.object,
    showJiraLoginButton: PropTypes.bool,
    url: PropTypes.string
};

export default OpsAlertsContent;