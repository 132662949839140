import React from 'react';
import PropTypes from "prop-types";

import undoIcon from "../../assets/undo.svg";
import checkIcon from "../../assets/check.svg";
import updateNotesIcon from "../../assets/update-notes.svg";
import viewConfigIcon from "../../assets/view-config.svg";
import createJiraIcon from "../../assets/create-ticket.svg";
import warningIcon from "../../assets/warning.png"
import closeIcon from "../../assets/close.svg"
import uploadIcon from "../../assets/upload.svg"
import removeIcon from "../../assets/remove.svg"
import fileUploadIcon from "../../assets/file-upload.svg"
import filterImage from "../../assets/filter.svg";

export const Icons = {
    NOTES: {icon: updateNotesIcon, alt: "Add Edit Notes", title: "Add/Edit Notes"},
    EDIT_ALERTS: {icon:updateNotesIcon, alt:"Edit Alert", title: "Edit Alert"},
    UNACKNOWLEDGED_ALERTS: {icon:undoIcon, alt:"Unacknowledge", title: "Unacknowledge"},
    ACKNOWLEDGED_ALERTS: {icon:checkIcon, alt:"Acknowledge", title: "Acknowledge"},
    VIEW_CONFIG: {icon:viewConfigIcon, alt:"View Json", title: "View Json"},
    CREATE_JIRA: {icon:createJiraIcon, alt:"Create JIRA", title: "Create JIRA"},
    WARNING: {icon:warningIcon, alt:"Alert not ready for editing", title: "Alert not ready for editing"},
    CLOSE: {icon:closeIcon, alt:"Close", title: "Close"},
    BATCH_ASSIGN_FROM_FILE_IMPORT: {icon:uploadIcon, alt:"Batch assign from file import", title: "Batch assign from file import"},
    REMOVE: {icon:removeIcon, alt:"Remove", title: "Remove"},
    UN_ASSIGN: {icon:removeIcon, alt:"Un-assign", title: "Un-assign"},
    FILE_UPLOAD: {icon:fileUploadIcon, alt:"File upload", title: "File upload"},
    FILTER: {icon:filterImage, alt:"Select Filters", title: "Select Filters"}
};


const IconImage = ({iconType, customClass}) => {
    return <img src={iconType.icon} alt={iconType.alt} title={iconType.title} className={customClass}/>;
};


IconImage.propTypes = {
    iconType: PropTypes.object,
    customClass: PropTypes.string
};

export default IconImage;